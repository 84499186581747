import moment from 'moment';

class DateService {

    public formatDate(date: Date|null, format: string): string|undefined {
        if (date instanceof Date) {
            return moment(date).format(format);
        }
    }

}

export default new DateService();
