
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import moment from 'moment';
import BaseLayout from '@/views/BaseLayout.vue';
import ViewHeader from '@/components/ViewHeader.vue';
import DateContext from '@/components/DateContext.vue';
import Panel from '@/components/Panel.vue';
import BasicTable from '@/components/BasicTable.vue';
import IconButton from '@/components/IconButton.vue';
import {BasicTableSeriesBuilder} from '@/util/BasicTableSeriesBuilder';
import exportService from '@/services/ExportService';
import {DateContextEvent, DateContextPeriod, DateContextTransaction, instanceOfDateContextPeriod, instanceOfDateContextTransaction, Period} from "@/models/date-context";
import {TableColumn} from '@/models/table';
import {FormatCurrencyAmount, FormatNumber} from "@/util/Formatter";
import {Currency} from '@/models/currency';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';
import loading from '@/util/Loading';
const namespace: string = 'financial';

@Component({
  components: {
    BaseLayout,
    ViewHeader,
    DateContext,
    Panel,
    BasicTable,
    IconButton,
  }
})
export default class PaymentServiceProviderView extends Vue {
  @Getter('dateContext') dateContext?: DateContextPeriod|DateContextEvent|DateContextTransaction;
  @Getter('periods') periods?: Period[];
  @Action('fetchPeriods') fetchPeriods: any;
  @Action('fetchDateContext') fetchDateContext: any;
  @Action('fetchSegmentSummaries') fetchSegmentSummaries: any;
  @Action('fetchPaymentServiceProviderDetailData', {namespace}) fetchPaymentServiceProviderDetailData: any;
  @Getter('defaultCurrency') defaultCurrency?: Currency;
  @Getter('paymentServiceProviderDetailData', { namespace }) paymentServiceProviderDetailData?: AnalyticsApiQueryResponse|null;

  get isLoading(): boolean {
    return loading.isLoadingJobType(['fetch-report', 'fetch-segment', 'fetch-period']);
  }

  get pageTitle(): string {
    return String(this.$t('financial.paymentServiceProvider')) + ': ' + this.$route.params.id;
  }

  get columns(): TableColumn[] {
    // const priceFormatter = new FormatCurrencyAmount(this.defaultCurrency ? this.defaultCurrency.symbol : '€', this.defaultCurrency ? this.defaultCurrency.exponent : 2);
    const priceFormatter = new FormatNumber(2);
    const dateFormatter = (v:string) => { return moment(v, 'YYYYMMDDHHmm').format('D MMM YYYY @ HH:mm'); };
    return [
      {key: 'customer', label: this.$t('common.customer')},
      {key: 'currencyCode', label: this.$t('common.currencyCode')},
      {key: 'paymentRevenue', label: this.$t('common.price'), format: priceFormatter.format.bind(priceFormatter)},
      // {key: 'paymentRevenue', label: this.$t('common.price')},
      {key: 'transactionDateHourMinute', label: this.$t('common.date'), format: dateFormatter},
    ];
  }

  get rows(): any[] {
    if(this.paymentServiceProviderDetailData) {
      const builder = new BasicTableSeriesBuilder(this.paymentServiceProviderDetailData);
      return builder.build();
    }
    return [];
  }

  public exportToCsv(): void {
    exportService.csv(this.rows, 'payment-service-provider.csv');
  }

  public fetchData(): void {
    this.fetchPaymentServiceProviderDetailData({psp: this.$route.params.id});
  }

  async mounted() {
    await this.fetchSegmentSummaries();
    await this.fetchPeriods();
    this.fetchDateContext();
    this.fetchData();
  }

}
