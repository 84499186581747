import {SeriesBuilder, ValueMap} from '@/util/SeriesBuilder';
import {AnalyticsApiQueryResponse, Row} from '@/models/analytics-api';
import {SegmentSummery} from '@/models/segment';
import {DateContextEvent, DateContextPeriod, DateContextTransaction, Period} from '@/models/date-context';
import {DataPoint, SeriesData} from '@ticketengine/chart/src/Models';


export class CartesianSeriesBuilder extends SeriesBuilder {
    private _valueMapX?: ValueMap;
    private _valueMapY?: ValueMap;
    private _valueMapZ?: ValueMap;

    constructor(
        response: AnalyticsApiQueryResponse,
        segments: SegmentSummery[],
        periods?: Period[],
        dateContext?: DateContextPeriod|DateContextEvent|DateContextTransaction,
        valueMapX?: ValueMap,
        valueMapY?: ValueMap,
        valueMapZ?: ValueMap,
    ) {
        super(response, segments, periods, dateContext);
        this._valueMapX = valueMapX;
        this._valueMapY = valueMapY;
        this._valueMapZ = valueMapZ;
    }

    public build(): SeriesData[] {
        const data: SeriesData[] = [];
        const segments = this._getSegmentsInData();

        let colorIndex = 0;
        this._getUniqueDateContextDimensionValues().forEach((value) => {
            segments.forEach((segment) => {
                data.push({
                    name: String(segment.name),
                    context: this._getSegmentContext(value),
                    unit: null,
                    color: this._colors[colorIndex],
                    data: this._getSegmentDataPoints(String(segment.name), value),
                });
                colorIndex++;
            });
        });
        return data;
    }


    private _getSegmentDataPoints(segmentName: string, dateContextDimensionValue?: string): DataPoint[] {
        const segmentRows = this._getFilteredRows(segmentName, dateContextDimensionValue);
        const dataPoints: DataPoint[] = [];
        segmentRows.forEach((row) => {
            dataPoints.push(this._getDataPoint(row));
        });
        return dataPoints;
    }


    private _getDataPoint(row: Row): DataPoint {
        if (!this._valueMapX || !this._valueMapY) {
            throw new Error('Missing value map(s).');
        }
        return {
            x: this._castValueToType(this._valueMapX, this._getDataPointValue(this._valueMapX, row)),
            y: this._castValueToType(this._valueMapY, this._getDataPointValue(this._valueMapY, row)),
            z: this._valueMapZ ? this._castValueToType(this._valueMapZ, this._getDataPointValue(this._valueMapZ, row)) : undefined,
        };
    }


    set valueMapX(value: ValueMap) {
        this._valueMapX = value;
    }

    set valueMapY(value: ValueMap) {
        this._valueMapY = value;
    }

    set valueMapZ(value: ValueMap) {
        this._valueMapZ = value;
    }

}
