
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import moment from 'moment';
import BaseLayout from '@/views/BaseLayout.vue';
import ViewHeader from '@/components/ViewHeader.vue';
import DateContext from '@/components/DateContext.vue';
import Panel from '@/components/Panel.vue';
import BasicTable from '@/components/BasicTable.vue';
import IconButton from '@/components/IconButton.vue';
import {BasicTableSeriesBuilder} from '@/util/BasicTableSeriesBuilder';
import {DateContextEvent, DateContextPeriod, DateContextTransaction, instanceOfDateContextPeriod, instanceOfDateContextTransaction, Period} from "@/models/date-context";
import exportService from '@/services/ExportService';
import {TableColumn} from '@/models/table';
import {FormatCurrencyAmount} from '@/util/Formatter';
import {Currency} from '@/models/currency';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';
import loading from '@/util/Loading';
const namespace: string = 'financial';

@Component({
  components: {
    BaseLayout,
    ViewHeader,
    DateContext,
    Panel,
    BasicTable,
    IconButton,
  }
})
export default class RegisterLedgerView extends Vue {
  @Getter('dateContext') dateContext?: DateContextPeriod|DateContextEvent|DateContextTransaction;
  @Getter('periods') periods?: Period[];
  @Getter('registerLedgerData', { namespace }) registerLedgerData?: AnalyticsApiQueryResponse|null;
  @Action('fetchPeriods') fetchPeriods: any;
  @Action('fetchDateContext') fetchDateContext: any;
  @Action('fetchSegmentSummaries') fetchSegmentSummaries: any;
  @Action('fetchRegisterLedgerData', { namespace }) fetchRegisterLedgerData: any;
  @Getter('defaultCurrency') defaultCurrency?: Currency;

  get isLoading(): boolean {
    return loading.isLoadingJobType(['fetch-report', 'fetch-segment', 'fetch-period']);
  }

  get pageTitle(): string {
    // return String(this.$t('financial.ledger')) + ': ' + this.$route.params.id;
    return String(this.$t('financial.ledger'));
  }

  get columns(): TableColumn[] {
    const priceFormatter = new FormatCurrencyAmount(this.defaultCurrency ? this.defaultCurrency.symbol : '€', this.defaultCurrency ? this.defaultCurrency.exponent : 2);
    const dateFormatter = (v:string) => { return moment(v).format('D MMM YYYY @ HH:mm'); };
    return [
      // {key: 'customer', label: '', link: {routeName: 'customer-detail', params: [{paramKey: 'id', valueKey: 'customerId'}]}},
      // {key: 'priceType', label: this.$t('metricsAndDimensions.name.te.priceType')},
      // {key: 'price', label: this.$t('common.price'), format: priceFormatter.format.bind(priceFormatter)},
      // {key: 'paymentMethod', label: this.$t('metricsAndDimensions.name.te.paymentMethod')},
      // {key: 'createdOn', label: this.$t('common.date'), format: dateFormatter},
      {key: 'customer', label: this.$t('common.customer')},
      {key: 'priceType', label: this.$t('metricsAndDimensions.name.te.priceType')},
      {key: 'sold', label: this.$t('common.quantity')},
      {key: 'revenue', label: this.$t('common.price'), format: priceFormatter.format.bind(priceFormatter)},
    ];
  }

  get rows(): any[] {
    if(this.registerLedgerData) {
      const builder = new BasicTableSeriesBuilder(this.registerLedgerData);
      return builder.build();
    }
    return [];
  }

  public exportToCsv(): void {
    exportService.csv(this.rows, 'register-ledger.csv');
  }

  public fetchData(): void {
    // this.fetchRegisterLedgerData({registerId: this.$route.params.id});
    this.fetchRegisterLedgerData({register: this.$route.params.id});
  }

  async mounted() {
    await this.fetchSegmentSummaries();
    await this.fetchPeriods();
    this.fetchDateContext();
    this.fetchData();
  }

}
