import { render, staticRenderFns } from "./SalesChannelLedgerView.vue?vue&type=template&id=fc3c168c&scoped=true&"
import script from "./SalesChannelLedgerView.vue?vue&type=script&lang=ts&"
export * from "./SalesChannelLedgerView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc3c168c",
  null
  
)

export default component.exports