import {SeriesBuilder} from '@/util/SeriesBuilder';
import {AnalyticsApiQueryResponse, Dimension, Metric} from '@/models/analytics-api';


export class BasicTableSeriesBuilder extends SeriesBuilder {

    constructor(
        response: AnalyticsApiQueryResponse,
    ) {
        super(response);
    }


    public build(): any[] {
        const values: any[] = [];
        if (this._response && this._response.data && this._response.data.rows) {
            this._response.data.rows.forEach((row) => {
                const r: {[key: string]: string | number} = {};
                row.dimensions.forEach((d, i) => {
                    const key: string = this._getKeyFromDimension(this._response.data.dimensions[i]);
                    r[key] = d;
                });
                row.metrics.forEach((m, i) => {
                    const key: string = this._getKeyFromMetric(this._response.data.metrics[i]);
                    r[key] = m;
                });
                values.push(r);
            });
        }
        return values;
    }

    private _getKeyFromDimension(dimension: Dimension): string {
        return dimension.name.replace('te.', '');
    }

    private _getKeyFromMetric(metric: Metric): string {
        return metric.name.replace('te.', '');
    }

}
